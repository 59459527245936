<div class="error-page-container">
	<div class="logo-container">
		<img
			src="./assets/images/kf-full-logo-red.svg"
			alt="Knight Frank logo"
    />
    <p class="logo-text">Healthcare Data Insights</p>
	</div>
	<div class="error-info-container">
		<p class="error-info-text">Error</p>
		<img
      src="./assets/images/404.svg"
      alt="404"
    />
    <div class="error-info-description">
	    <p>We can't seem to find the page you are looking for</p>
	    <p>Here are some helpful links for you.</p>
    </div>
	</div>
	<div class="btn-container">
		<a [routerLink]="['/']">
			<button 
				class="btn btn-primary">
				Homepage
			</button>
		</a>
		<button 
			(click)="goBack()"
			class="btn btn-secondary btn-go-back">
			Return to last page
		</button>
	</div>
</div>

