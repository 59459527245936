import {createAction, props} from '@ngrx/store';
import {Area} from './data-comparison.reducer';
import {DataComparisonRequestOptions} from '../../services/data-comparison-http.service';

export const setArea = createAction(
  '[DataComparison] Set comparison area A or B',
  props<{areaId: 'areaA' | 'areaB', area: Area}>()
);

export const loadDataComparisons = createAction(
  '[DataComparison] Load DataComparisons',
);

export const loadDataComparisonsSuccess = createAction(
  '[DataComparison] Load DataComparisons SUCCESS',
  props<{
    areaAPerformance: any[],
    areaBPerformance: any[],
    options: DataComparisonRequestOptions,
  }>()
);

export const loadDataComparisonsFailure = createAction(
  '[DataComparison] Load DataComparisons FAILURE',
  props<{ error: any }>()
);

export const openComparisonMapView = createAction(
  '[DataComparison] Open data comparison map view'
);

export const closeComparisonMapView = createAction(
  '[DataComparison] Close data comparison map view'
);

export const toggleComparisonMapView = createAction(
  '[DataComparison] Toggle data comparison map view'
);

export const setHasLocation = createAction(
  '[DataComparison] Set has location property',
  props<{ hasLocation: boolean }>()
);

export const setCustomSearch = createAction(
'[DataComparison] Set custom search property and map active properties',
  props<{ custom: boolean}>()
);

export const setCurrentComparisonSelection = createAction(
  '[DataComparison] Set data comparison property',
  props<{ currentSelection: 'areaA' | 'areaB'}>()
);
