import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {catchError, concatMap, map} from 'rxjs/operators';
import {of} from 'rxjs';
import * as UserActions from '../actions/user.actions';
import {UserProfileService} from '../../../core/services/user-profile.service';

@Injectable()
export class UserEffects {

  loadUsers$ = createEffect(() =>
     this.actions$.pipe(
      ofType(UserActions.loadUsers),
      concatMap(() =>
        this.userProfileService.getUserProfile().pipe(
          map(data => UserActions.loadUsersSuccess({data})),
          catchError(error => of(UserActions.loadUsersFailure({error})))))));

  constructor(
    private actions$: Actions,
    private userProfileService: UserProfileService,
  ) {
  }

}
