import {createReducer, on} from '@ngrx/store';
import * as UIActions from './actions/ui.actions';

export const uIFeatureKey = 'uI';

export interface State {
  sideBarOpen: boolean;
  mapOrTableView: 'map' | 'table';
  overLayOpen: boolean;
  tooltipComponent: string | undefined;
  tooltipData: any | undefined;
  activeTableFilter: TableFilters | undefined;
}

export type TableFilters = 'careHomeType'
  | 'numBeds'
  | 'yearRegistered'
  | 'careHomeName'
  | 'applicant'
  | 'localAuthority'
  | 'constructionType'
  | 'numWetRooms'
  | 'numPlannedBeds'
  | 'groupName'
  | 'proposals'
  | 'planningStatuses'
;

export const initialState: State = {
  sideBarOpen: true,
  mapOrTableView: 'map',
  overLayOpen: false,
  tooltipComponent: undefined,
  tooltipData: undefined,
  activeTableFilter: undefined,
};

export const reducer = createReducer(
  initialState,
  on(UIActions.setTooltipOpen, (state, {tooltipComponent, tooltipData}) => ({...state, tooltipComponent, tooltipData})),
  on(UIActions.setTooltipClosed, (state) => ({...state, tooltipComponent: undefined, tooltipData: undefined})),
  on(UIActions.toggleSideBarOpen, (state) => ({...state, sideBarOpen: !state.sideBarOpen})),
  on(UIActions.setOverlayOpen, (state) => ({...state, overLayOpen: true})),
  on(UIActions.setOverlayClosed, (state) => ({...state, overLayOpen: false})),
  on(UIActions.openSideBar, (state) => ({...state, sideBarOpen: true})),
  on(UIActions.setMapView, (state) => ({
    ...state,
    mapOrTableView: 'map',
  })),
  on(UIActions.setTableView, (state) => ({
    ...state,
    mapOrTableView: 'table',
  })),
  on(UIActions.setTableFilterOpen, (state, { filterName }) => {
    return ({ ...state, activeTableFilter: filterName });
  }),
  on(UIActions.setTableFiltersClosed, (state) => ({...state, activeTableFilter: undefined })),
);
