<div class="error-page-container">
  <div class="logo-container">
    <img
      src="./assets/images/kf-full-logo-red.svg"
      alt="Knight Frank logo"
    />
    <p class="logo-text">Healthcare Data Insights</p>
  </div>
  <div class="error-info-container">
    <p class="error-info-text">Error</p>
    <div class="error-code-container">
      <p class="error-code-text">401</p>
    </div>
    <div class="error-info-description">
      <p class="no-access-text">You do not have permission to view this page</p>
      <p>Please contact your Knight Frank representative <br />if you believe your access has been denied in error</p>
    </div>
    <button
      matRipple
      class="btn btn-secondary center"
      (click)="logout()">Log out</button>
  </div>
</div>

