import {Injectable} from '@angular/core';
import {Actions, createEffect, ofType} from '@ngrx/effects';
import {concatMap, map} from 'rxjs/operators';
import {EMPTY} from 'rxjs';

import * as UIActions from '../actions/ui.actions';

@Injectable()
export class UIEffects {
  loadUIs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UIActions.toggleSideBarOpen),
      concatMap(() =>
        /** An EMPTY observable only emits completion. Replace with your own observable API request */
        EMPTY.pipe(map((data) => UIActions.toggleSideBarOpen()))
      )
    );
  });

  constructor(private actions$: Actions) {
  }
}
