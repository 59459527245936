import { Injectable } from '@angular/core';
import { MsalBroadcastService } from '@azure/msal-angular';
import { EventMessage } from '@azure/msal-browser';
import { Angulartics2GoogleGlobalSiteTag } from 'angulartics2/gst';
import { Observable, Observer, of, throwError } from 'rxjs';
import { concatMap, filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class GtagService {
  private document: Document;
  get trackingId(): string {
    return environment.gtagTrackingId;
  }

  /**
   * initialize google code by adding google code to page as observable
   * <script async src="https://www.googletagmanager.com/gtag/js?id=UA-XXXXXX-XXX"></script>
   * @returns Error or void
   */
  private loadGoogleTagApi(): Observable<never | void> {
    if (!!this.trackingId === false) {
      return throwError(
        new Error('environment.gtagTrackingId must be defined')
      );
    }
    return new Observable((observer: Observer<void>) => {
      const gmscript = this.document.createElement('script');
      gmscript.async = true;
      gmscript.src = `https://www.googletagmanager.com/gtag/js?id=${this.trackingId}`;
      gmscript.onload = () => {
        observer.next();
        observer.complete();
      };
      this.document.head.appendChild(gmscript);
    });
  }

  /**
   * adds google dataLayer and gtag to window object
   */
  private setGtag(): void {
    const win = this.window as any;
    win.dataLayer = win.dataLayer || [];
    /* tslint:disable */
    win.gtag = function () {
      (window as any).dataLayer.push(arguments);
    };
    /* tslint:enable */
    win.gtag('js', new Date());
  }

  /**
   * get username userid vip and organization
   */
  private msalLoginBroadcast(): void {
    this.msalBroadCastService.msalSubject$
      .pipe(filter((e): boolean => e.eventType === 'msal:loginSuccess'))
      .pipe(
        map((evtMessage: EventMessage) => {
          const getval = (attr: string): string => {
            const payload: any = evtMessage.payload;
            if (payload && payload.account && payload.account[attr]) {
              return payload.account[attr];
            }
            return '';
          };

          const userId = getval('localAccountId');
          const organization = getval('organization');
          const vip = getval('vip');
          this.successfulLogin(userId, vip, organization);
        })
      )
      .subscribe();
  }

  /**
   * adds parameters to dataLayer
   */
  gtagPush(params: any): void {
    const win = this.window as any;

    /* tslint:enable */
    win.gtag(params);
  }

  /**
   * starts partial pageview tracking
   */
  initialise(): Promise<any> {
    return this.loadGoogleTagApi()
      .pipe(
        concatMap(() => {
          this.analytics.startTracking();
          return of(true);
        })
      )
      .toPromise();
  }

  /**
   * sends stats to gtag on successful login
   * @param userid user identification known by KF obsfucated from gtag
   * @param role is vip or not user yes, no
   * @param organization users organization
   */
  successfulLogin(userid: string, role: string, organization: string): void {
    let vip = role;
    if ( role !== 'Yes' && role !== 'No' ) {
      vip = role === 'Full' ? 'Yes' : 'No';
    }
    this.analytics.setUserProperties({
      userId: userid,
      member_name: organization,
      vip_member: vip,
    });

    const model = this.getEventModel('iHealthcare_login_success', {
      userId: userid,
      member_name: organization,
      vip_member: vip,
    });

    this.gtagPush(model);
  }

  /**
   * returns an object model containing data for gtag
   * @param event event to track
   * @param params parameters/data to include
   */
   getEventModel(event: string, params: object): object {
    return {
      event,
      ...params
    };
  }

  constructor(
    private window: Window,
    private analytics: Angulartics2GoogleGlobalSiteTag,
    private msalBroadCastService: MsalBroadcastService
  ) {
    this.document = (this.window as any).document;
    this.setGtag();
    this.msalLoginBroadcast();
  }
}
