import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UserProfileService {

  private uri = environment.functionAppUri + 'userprofile';

  constructor(
    private httpClient: HttpClient,
  ) {
  }

  getUserProfile(): Observable<any> {
    return this.httpClient
      .get(this.uri);
  }
}
