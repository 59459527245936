import { Component } from '@angular/core';
import {MsalService} from '@azure/msal-angular';

@Component({
  selector: 'kfm-access-denied-view',
  templateUrl: './access-denied-view.component.html',
  styleUrls: ['./access-denied-view.component.scss'],
})
export class AccessDeniedViewComponent {

  constructor(
    private msalService: MsalService,
  ) { }

  logout(): void {
    this.msalService.logout();
  }
}
