import { ErrorHandler, Injectable } from '@angular/core';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { environment } from 'projects/knight-frank-healthcare-main/src/environments/environment';
import { NetworkService } from './network.service';

@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  appInsights!: ApplicationInsights;
  /**
   * Sends error to BE for data capture as KF IT sercurity requirement
   * @param error
   * Error
   */
  handleError(error: Error): void {
    if (this.networkService.online) {
      this.appInsights.trackException({ exception: error });
    }
  }

  constructor(private networkService: NetworkService) {
    this.appInsights = new ApplicationInsights({
      config: {
        enableAutoRouteTracking: true,
        connectionString: environment.appInsightsConfig.connectionString, // provided by Azure
        /* ...Other Configuration Options... */
      },
    });
    this.appInsights.loadAppInsights();
    this.appInsights.trackPageView();
  }
}
