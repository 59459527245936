import {APP_INITIALIZER, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, Title} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalInterceptor,
  MsalModule,
  MsalService,
} from '@azure/msal-angular';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './material/material.module';
import {HTTP_INTERCEPTORS, HttpClientJsonpModule, HttpClientModule} from '@angular/common/http';
import {StoreModule} from '@ngrx/store';
import {metaReducers, reducers} from './store';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {environment} from '../environments/environment';
import {EffectsModule} from '@ngrx/effects';
import {AppEffects} from './store/effects/app.effects';
import {UserEffects} from './store/user/effects/user.effects';
import {UIEffects} from './store/ui/effects/ui.effects';

import {MSALGuardConfigFactory, MSALInstanceFactory, MSALInterceptorConfigFactory} from './b2c-config';
import * as fromAuth from './store/auth/auth.reducer';
import {AuthEffects} from './store/auth/effects/auth.effects';
import {GtagEffects} from './store/gtag/effects/gtag.effects';
import {GlobalErrorHandler} from './core/error/global-error-handler';
import {NetworkService} from './core/error/network.service';
import {Angulartics2Module} from 'angulartics2';
import {GtagService} from './gtag.service';
import {NoAuthInterceptor} from './core/interceptors/no-auth.interceptor';

export function initialiseAnalyticsFactory(gtagService: GtagService): any {
  return () => gtagService.initialise();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    MsalModule,
    MaterialModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([AppEffects]),
    EffectsModule.forFeature([
      UserEffects,
      UIEffects,
      AuthEffects,
      GtagEffects
    ]),
    StoreModule.forFeature(fromAuth.authFeatureKey, fromAuth.reducer),
    StoreDevtoolsModule.instrument(),
    Angulartics2Module.forRoot({
      developerMode: !environment.production, // false for dev debugging
      gst: {
        trackingIds: [environment.gtagTrackingId],
      },
    }),
  ],
  providers: [
    Window,
    {provide: Window, useValue: window},
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: NoAuthInterceptor,
      multi: true
    },
    MsalGuard,
    MsalBroadcastService,
    NetworkService,
    {
      // processes all errors can only be provided in appModule
      provide: ErrorHandler,
      useClass: GlobalErrorHandler,
      deps: [NetworkService],
    },
    GtagService,
    {
      provide: APP_INITIALIZER,
      useFactory: initialiseAnalyticsFactory,
      deps: [GtagService, Window],
      multi: true,
    },
    Title,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private networkService: NetworkService) {
    this.networkService.init();
  }
}
