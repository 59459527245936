import {createReducer, on} from '@ngrx/store';
import * as UserActions from './actions/user.actions';

export const userFeatureKey = 'user';

export interface State {
  loadingUser: boolean;
  errorLoadingUser: boolean;
  userProfile: {
    name: string | undefined;
    email: string | undefined;
    role: 'Full' | 'Partial' | undefined;
    provider: string | undefined;
    userId: string | undefined;
  },
  googleMapsApiKey: string | undefined;
}

export const initialState: State = {
  loadingUser: true,
  errorLoadingUser: false,
  userProfile: {
    name: undefined,
    email: undefined,
    role: undefined,
    provider: undefined,
    userId: undefined,
  },
  googleMapsApiKey: undefined
};

export const reducer = createReducer(
  initialState,

  on(UserActions.loadUsers, state => ({
    ...state,
    loadingUser: true,
    errorLoadingUser: false,
  })),
  on(UserActions.loadUsersSuccess, (state, {data}) => {
    const user = {
      googleMapsApiKey: data.googleMapsApiKey,
      userProfile: data.userProfile[0]
    };
    return {
      ...state,
    ...user,
    loadingUser: false,
    };
  }),
  on(UserActions.loadUsersFailure, (state) => ({
    ...state,
    loadingUser: false,
    errorLoadingUser: true,
  })),
);

