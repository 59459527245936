import { Injectable } from '@angular/core';
import { fromEvent, merge, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { SnackBarService } from '../snack-bar/snack-bar.service';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private isOnline = false;
  private offLineMessage = 'No internet connection detected';
  get online(): boolean {
    return this.isOnline;
  }
  private createOnline$(): Observable<boolean> {
    return merge(
      of(null),
      fromEvent(this.window, 'online'),
      fromEvent(this.window, 'offline')
    ).pipe(
      map(() => {
        this.isOnline = this.window.navigator.onLine;
        if (!this.isOnline) {
          this.snackBarService.openSnackBar(this.offLineMessage);
        }
        return this.isOnline;
      })
    );
  }
  constructor(
    private window: Window,
    private snackBarService: SnackBarService
  ) {}

  init(): void {
    this.createOnline$().subscribe();
  }
}
