import {createAction, props} from '@ngrx/store';

export const setOnPage = createAction(
  '[Demographics] Set on page',
  props<{ onPage: boolean }>()
);

export const toggleDemographicsMapView = createAction(
  '[Demographics] Toggle map view',
);

export const setDemographicsMapView = createAction(
  '[Demographics] Set map view',
  props<{ onMapView: boolean }>()
);

export const loadDemographicsByRadius = createAction(
  '[Demographics] Load Demographics by radius',
  props<{ searchObject: any }>()
);

export const loadDemographicsByPolygon = createAction(
  '[Demographics] Load Demographics by polygon',
  props<{ searchObject: any }>()
);

export const loadPopulationByPolygon = createAction(
  '[demographics] load population by polygon',
  props<{ searchObject: any }>());

export const loadPopulationByRadius = createAction(
  '[demographics] load population by radius',
  props<{ searchObject: any }>());

export const loadMosaicMapByRadius = createAction(
  '[Demographics] Load Mosaic map data by radius',
  props<{ searchObject: any }>()
);

export const loadMosaicMapSuccess = createAction(
  '[Demographics] Load Mosaic map Success',
  props<{ data: any }>()
);

export const loadMosaicMapFailure = createAction(
  '[Demographics] Load Mosaic map Failure',
);

export const loadMosaicMapByPolygon = createAction(
  '[Demographics] Load Mosaic map data by polygon',
  props<{ searchObject: any }>()
);

export const loadPopulationSuccess = createAction(
  '[Demographics] Load Population Success',
  props<{ data: any }>()
);

export const loadPopulationFailure = createAction(
  '[Demographics] Load Population Failure',
);

export const loadHousePricesSuccess = createAction(
  '[Demographics] Load HousePrices Success',
  props<{ data: any }>()
);

export const loadUKHousePricesSuccess = createAction(
  '[Demographics] Load UK HousePrices Success',
  props<{ data: any }>()
);

export const loadHousePricesFailure = createAction(
  '[Demographics] Load HousePrices Failure',
);

export const loadMosaicSuccess = createAction(
  '[Demographics] Load Mosaic Success',
  props<{ data: any }>()
);

export const loadMosaicFailure = createAction(
  '[Demographics] Load Mosaic Failure',
);
