import { ActionReducerMap, MetaReducer } from '@ngrx/store';
import * as fromUI from './ui/ui.reducer';
import * as fromUser from './user/user.reducer';
import * as fromAuth from './auth/auth.reducer';

export interface State {
  [fromUI.uIFeatureKey]: fromUI.State;
  [fromUser.userFeatureKey]: fromUser.State;
  [fromAuth.authFeatureKey]: fromAuth.State;
}

export const reducers: ActionReducerMap<State> = {
  [fromUI.uIFeatureKey]: fromUI.reducer,
  [fromUser.userFeatureKey]: fromUser.reducer,
  [fromAuth.authFeatureKey]: fromAuth.reducer,
};

export const metaReducers: MetaReducer<State>[] = [];
