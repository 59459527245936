// @SONAR_STOP@
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { tap, withLatestFrom, filter } from 'rxjs/operators';

import * as UserActions from '../../user/actions/user.actions';
import * as MapActions from '../../../healthcare/store/map/map.actions';
import * as DemographicsActions from '../../../healthcare/store/demographics/demographics.actions';
import * as CareHomeActions from '../../../healthcare/store/care-homes/care-homes.actions';
import * as PolygonActions from '../../../healthcare/store/user-polygon/user-polygon.actions';
import * as DataComparisonActions from '../../../healthcare/store/data-comparison/data-comparison.actions';
import * as ExpanderActions from '../../../healthcare/store/expander/expander.actions';
import { GtagService } from '../../../gtag.service';

import { NavigationEnd, Router } from '@angular/router';

@Injectable()
export class GtagEffects {
  searchParams = {
    search_area: '',
    radius: 0,
    measure: ''
  };
  drawParams = {
    user_long: '',
    user_lat: ''
  };
  currentSearchType = 'search';

  // User loaded
  loadUsers$ = createEffect(() =>
    this.actions$.pipe(
    ofType(UserActions.loadUsersSuccess),
    tap((params) => {
      this.gtag.successfulLogin(params.data[0].userId, params.data[0].role, params.data[0].provider);
    })), {dispatch: false});

  // Location loaded
  getLocation$ = createEffect(() =>
    this.actions$.pipe(
    ofType(MapActions.getLocation),
    withLatestFrom(this.router$.events.pipe(
      filter((value) => {
        return value instanceof NavigationEnd;
      })
    )),
    tap(([params, events]) => {
      const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');
      this.currentSearchType = 'search';

      this.searchParams.search_area = params.searchText;
      this.searchParams.radius = params.radius;
      this.searchParams.measure = params.distanceType;

      const model = this.gtag.getEventModel(`${page}_search`, {
        explore_area: {
          search_area: params.searchText,
          radius: params.radius,
          measure: params.distanceType
        }
      });

      this.gtag.gtagPush(model);
    })), {dispatch: false});

  // Polygon loaded
  loadPopulationByPolygon$ = createEffect(() =>
    this.actions$.pipe(
    ofType(DemographicsActions.loadPopulationByPolygon),
    withLatestFrom(this.router$.events.pipe(
      filter((value) => {
        return value instanceof NavigationEnd;
      })
    )),
    tap(([params, events]) => {
      const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');
      this.currentSearchType = 'draw';

      this.drawParams.user_lat = params.searchObject.bounds.lat;
      this.drawParams.user_long = params.searchObject.bounds.lng;

      const model = this.gtag.getEventModel(`${page}_drawn_map_load`, {
        area: 'drawn_map_load',
        user_area: {
          user_long: params.searchObject.bounds.lng,
          user_lat: params.searchObject.bounds.lat,
        }
      });

      this.gtag.gtagPush(model);
    })), {dispatch: false});

  // Delete polygon
  deletePolygon$ = createEffect(() =>
  this.actions$.pipe(
  ofType(PolygonActions.clearUserPolygons),
  withLatestFrom(this.router$.events.pipe(
    filter((value) => {
      return value instanceof NavigationEnd;
    })
  )),
  tap(([params, events]) => {
    const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');

    const model = this.gtag.getEventModel(`${page}_drawn_map_change`, {
      area: 'drawn_map_load',
      button: 'Delete Polygon'
    });

    this.gtag.gtagPush(model);
  })), {dispatch: false});

  // Move Map Click
  moveMap$ = createEffect(() =>
  this.actions$.pipe(
  ofType(MapActions.moveMapClick),
  withLatestFrom(this.router$.events.pipe(
    filter((value) => {
      return value instanceof NavigationEnd;
    })
  )),
  tap(([params, events]) => {
    const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');

    const model = this.gtag.getEventModel(`${page}_drawn_map_change`, {
      area: 'drawn_map_load',
      button: 'Move Map'
    });

    this.gtag.gtagPush(model);
  })), {dispatch: false});

  // Draw Polygon Click
  drawPolygon$ = createEffect(() =>
  this.actions$.pipe(
  ofType(PolygonActions.drawPolygonClick),
  withLatestFrom(this.router$.events.pipe(
    filter((value) => {
      return value instanceof NavigationEnd;
    })
  )),
  tap(([params, events]) => {
    const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');

    const model = this.gtag.getEventModel(`${page}_drawn_map_change`, {
      area: 'drawn_map_load',
      button: 'Draw Polygon'
    });

    this.gtag.gtagPush(model);
  })), {dispatch: false});

  // Status view clicked
  statusViewClicked$ = createEffect(() =>
    this.actions$.pipe(
    ofType(CareHomeActions.setCareHomeStatusView),
    tap((params) => {
      const supply = params.status === 'planned' ? 'future_supply' : 'current_supply';
      let model = {};

      if (this.currentSearchType === 'search') {
        model = this.gtag.getEventModel(`competition_overview_search`, {
          section: supply,
          explore_area: {
            ...this.searchParams
          }
        });
      }

      if (this.currentSearchType === 'draw') {
        model = this.gtag.getEventModel(`competition_drawn_map_load`, {
          area: 'drawn_map_load',
          section: supply,
          user_area: {
            ...this.drawParams
          }
        });
      }

      this.gtag.gtagPush(model);
    })), {dispatch: false});

  // Select Care Home
  selectCareHome$ = createEffect(() =>
  this.actions$.pipe(
  ofType(CareHomeActions.selectCareHomeAction),
  withLatestFrom(this.router$.events.pipe(
    filter((value) => {
      return value instanceof NavigationEnd;
    })
  )),
  tap(([params, events]) => {
    const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');

    let model = {};

    if (this.currentSearchType === 'search') {
      model = this.gtag.getEventModel(`${page}_search_location`, {
        explore_area: {
          ...this.searchParams
        },
        care_home_location : {
          care_home_locationName: params.name,
          care_home_locationLat: params.lat,
          care_home_locationLong: params.lng,
          care_home_locationMiles: 0,
          care_home_locationMinutes: 0
        }
      });
    }

    if (this.currentSearchType === 'draw') {
      model = this.gtag.getEventModel(`${page}_drawn_map_load`, {
        user_area: {
          ...this.drawParams
        },
        care_home_location : {
          care_home_locationName: params.name,
          care_home_locationLat: params.lat,
          care_home_locationLong: params.lng,
          care_home_locationMiles: 0,
          care_home_locationMinutes: 0
        }
      });
    }

    this.gtag.gtagPush(model);
  })), {dispatch: false});

  // Expander Click
  expanderClick$ = createEffect(() =>
  this.actions$.pipe(
  ofType(ExpanderActions.Expanded),
  withLatestFrom(this.router$.events.pipe(
    filter((value) => {
      return value instanceof NavigationEnd;
    })
  )),
  tap(([params, events]) => {
    const page = (events as NavigationEnd).url.replace('/', '').replace('-', '_');

    let model = {};

    if (this.currentSearchType === 'search') {
      model = this.gtag.getEventModel(`${page}_search_section`, {
        explore_area: {
          ...this.searchParams
        },
        section_name: params.section,
        section_action: params.direction
      });
    }

    if (this.currentSearchType === 'draw') {
      model = this.gtag.getEventModel(`${page}_drawn_map_section`, {
        user_area: {
          ...this.drawParams
        },
        section_name: params.section,
        section_action: params.direction
      });
    }

    this.gtag.gtagPush(model);
  })), {dispatch: false});

  // Data comparison click
  dataComparisonClick$ = createEffect(() =>
    this.actions$.pipe(
    ofType(DataComparisonActions.loadDataComparisonsSuccess),
    tap((params) => {
      const model = this.gtag.getEventModel('dataComparison_compareButton', {
        areaA: {
          id: params.options.areaAId,
          name: params.options.areaAName,
          type: params.options.areaAType
        },
        areaB: {
          id: params.options.areaBId,
          name: params.options.areaBName,
          type: params.options.areaBType
        }
      });

      this.gtag.gtagPush(model);
    })), {dispatch: false});

  constructor(private actions$: Actions, private gtag: GtagService, private router$: Router) {}
}
// @SONAR_START@
