import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MsalGuard } from '@azure/msal-angular';
import { AccessDeniedViewComponent } from './core/access-denied-view/access-denied-view.component';
import { NotFoundViewComponent } from './core/not-found-view/not-found-view.component';

const routes: Routes = [
  {
    path: '',
    canActivate: [MsalGuard],
    loadChildren: () => import('./authorised-user/authorised-user.module').then(m => m.AuthorisedUserModule),
  },
  {
    path: 'access-denied',
    component: AccessDeniedViewComponent,
  },
  {
    path: '**',
    component: NotFoundViewComponent
  }
];

const isIframe = window !== window.parent && !window.opener;

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: !isIframe ? 'enabled' : 'disabled' // Don't perform initial navigation in iframes
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
