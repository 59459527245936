import { createAction, props } from '@ngrx/store';
import { LatLng } from '../../../maps/google-map/lat-long.model';
import { DistanceType } from './map.reducer';

export const loadGmapJs = createAction(
  '[Map] Load Gmaps javascript library',
);

export const loadGmapJsSuccess = createAction(
  '[Map] Load Gmaps javascript library SUCCESS',
);

export const loadGmapJsFailure = createAction(
  '[Map] Load Gmaps javascript library FAILURE',
);

export const getLocation = createAction(
  '[Map] Get Location',
  props<{ searchText: string, distanceType: DistanceType, radius: number, page: string, homeType?: string }>()
);

export const getLocationFromPage = createAction(
  '[Map] Get Location from page',
  props<{ page: string }>()
);

export const getLocationSuccess = createAction(
  '[Map] Get Location Success',
  props<{ center: LatLng, page: string }>()
);

export const doNothingAction = createAction(
  '[Map] Do nothing',
);

export const getLocationFailure = createAction(
  '[Map] Get Location Failure',
  props<{ error: any }>()
);

export const loadIsochrone = createAction(
  '[Map] Load Isochrone'
);
export const loadIsochroneSuccess = createAction(
  '[Map] Load Isochrone Success',
  props<{ isochrone: LatLng[] }>()
);

export const loadIsochroneFailure = createAction(
  '[Map] Load Isochrone Failure',
  props<{ error: any }>()
);

export const toggleDrawingToolsActive = createAction(
  '[Map] Toggle drawing tools'
);

export const setDrawingToolsActive = createAction(
  '[Map] Set drawing tools active'
);

export const setDrawingToolsInactive = createAction(
  '[Map] Set drawing tools INACTIVE'
);

export const moveMapClick = createAction(
  '[Map] Move map button click'
);

export const setMapCenterAndZoom = createAction(
  '[Map] Set center and zoom of map',
  props<{ mapCenter: LatLng, zoom: number }>()
);

export const setComparison = createAction(
  '[Map] Set comparison properties',
  props<{ isComparing: boolean, comparisonSide: 'areaA' | 'areaB' }>()
);

export const setLocationSearchFields = createAction(
  '[Map] Set search fields',
  props<{ searchText: string, radius: number, distanceType: DistanceType | undefined }>()
);

export const getLocationSearchFields = createAction(
  '[Map] get search fields'
);
