/**
 * Enter here the user flows and custom policies for your B2C application
 * To learn more about user flows, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/user-flow-overview
 * To learn more about custom policies, visit: https://docs.microsoft.com/en-us/azure/active-directory-b2c/custom-policy-overview
 */
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  Configuration,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication,
} from '@azure/msal-browser';
import {environment} from '../environments/environment';

const isIE =
  window.navigator.userAgent.indexOf('MSIE ') > -1 ||
  window.navigator.userAgent.indexOf('Trident/') > -1;

const loggercallback = (
  level: LogLevel,
  message: string,
  containsPii: boolean
): void => {
  if (containsPii) {
    return;
  }
  // tslint:disable:no-console
  switch (level) {
    case LogLevel.Error:
      console.error(message);
      return;
    case LogLevel.Info:
      console.info(message);
      return;
    case LogLevel.Verbose:
      console.debug(message);
      return;
    case LogLevel.Warning:
      console.warn(message);
      return;
  }
  // tslint:enable:no-console
};

export const b2cConfiguration: Configuration = {
  // MSAL Configuration
  auth: {
    clientId: environment.b2cClientId,
    authority: environment.b2CAuthority,
    redirectUri: '/',
    postLogoutRedirectUri: '/',
    knownAuthorities: [environment.b2CAuthorityDomain],
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: isIE, // set to true for IE 11
  },
  system: {
    loggerOptions: {
      // loggerCallback: loggercallback,
      logLevel: LogLevel.Error,
      piiLoggingEnabled: false,
    },
  },
};

export const msalGaurdConfig: MsalGuardConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Guard Configuration
  authRequest: {
    scopes: [...environment.b2CScopes],
  },
};

export const msalInterceptorConfig: MsalInterceptorConfiguration = {
  interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
  protectedResourceMap: new Map([
    ['https://graph.microsoft.com/v1.0/me', [...environment.b2CScopes]],
    [environment.functionAppUri, [...environment.b2CScopes]],
  ]),
};


export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(b2cConfiguration);
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return msalGaurdConfig;
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return msalInterceptorConfig;
}
