import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { CareHome } from './care-homes.model';
import {HttpErrorResponse} from '@angular/common/http';
import {CareHomeStatus, FiltersState} from './care-homes.reducer';

export const loadCareHomes = createAction(
  '[CareHomes/API] Load CareHomes',
);

export const loadCareHomesSuccess = createAction(
  '[CareHomes/API] Load CareHomes Success',
  props<{ careHomes: CareHome[] }>()
);

export const loadCareHomesFailure = createAction(
  '[CareHomes/API] Load CareHomes Failure',
  props<{ error: HttpErrorResponse }>()
);

export const addCareHome = createAction(
  '[CareHomes/API] Add One CareHome',
  props<{ careHome: CareHome }>()
);

export const upsertCareHome = createAction(
  '[CareHomes/API] Upsert One CareHome',
  props<{ careHome: CareHome }>()
);

export const addManyCareHomes = createAction(
  '[CareHomes/API] Add Many CareHomes',
  props<{ careHomes: CareHome[] }>()
);

export const upsertManyCareHomes = createAction(
  '[CareHomes/API] Upsert Many CareHomes',
  props<{ careHomes: CareHome[] }>()
);

export const updateCareHome = createAction(
  '[CareHomes/API] Update One CareHome',
  props<{ careHome: Update<CareHome> }>()
);

export const deleteCareHomes = createAction(
  '[CareHomes/API] Delete CareHome',
  props<{ id: string }>()
);

export const clearCareHomes = createAction(
  '[CareHomes/API] Clear CareHomes'
);

export const selectCareHomeAction = createAction(
  '[CareHomes/API] Select CareHome action',
  props<{
    id: string,
    name: string,
    lat: number,
    lng: number
  }>()
);

export const clearSelectedCareHome = createAction(
  '[CareHomes/API] Clear selected CareHome',
);

export const setCareHomeStatusView = createAction(
  '[CareHomes/API] Set care home status view',
  props<{ status: CareHomeStatus }>()
);

export const setCurrentCareHomeDescription = createAction(
  '[CareHome] Set care home description property',
  props<{ currentCareHomeDescription: '' }>()
);

export const updateCareHomeFilters = createAction(
  '[CareHomes] Update the Care Home filter value',
  props<{ filters: FiltersState }>()
);

export const updateNumBedsFilters = createAction(
  '[CareHomes] Update the Care Home minimum/maximum number of beds filter',
  props<{ minBeds: number | null, maxBeds: number | null }>()
);

export const updateNumWetRoomsFilters = createAction(
  '[CareHomes] Update the Care Home minimum/maximum number of wet rooms filter',
  props<{ minWetRooms: number | null, maxWetRooms: number | null }>()
);

export const updateNumPlannedBedsFilters = createAction(
  '[CareHomes] Update the Care Home minimum/maximum number of planned beds filter',
  props<{ minPlannedBeds: number | null, maxPlannedBeds: number | null }>()
);

export const updateYearRegisteredFilters = createAction(
  '[CareHomes] Update the Care Home min/max year filter',
  props<{ minYear: number | null, maxYear: number | null}>()
)

export const updateCareHomeNameFilters = createAction(
  '[CareHomes] Update the Care Home names filter',
  props<{ names: [] }>()
);

export const resetFilters = createAction(
  '[CareHomes] Reset all applied filters',
);
