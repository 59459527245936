import {Component, OnInit} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {ActivatedRoute, NavigationEnd, Router, Event, NavigationStart} from '@angular/router';
import {filter, map} from 'rxjs/operators';
import {environment} from '../environments/environment';
import { GtagService } from './gtag.service';

@Component({
  selector: 'kfm-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  public cspTags = [
    {name: `default-src`, content: `'self';`},
    {name: `frame-src`, content: `'self' https://knightfrankb2cdevtest.b2clogin.com/;`},

    {
      name: `style-src`,
      content: `'self' 'unsafe-inline' fonts.googleapis.com *.gstatic.com;`,
    },
    {name: `font-src`, content: `'self' *.gstatic.com;`},
    {
      name: `connect-src`,
      content: `'self' *.ihealthcare.azurefd.net/ https://ihealthcare.azurefd.net/api/carehomes https://ihealthcare.azurefd.net/api/datacomparison https://ihealthcare.azurefd.net/api/usercarehomes *.b2clogin.com *.applicationinsights.azure.com *.googleapis.com *.hereapi.com www.google-analytics.com https://devtestsecurity.azurewebsites.net;`,
    },
    {
      name: `script-src`,
      content: `'self' *.googleapis.com ${this.allowedJasmineCsp} www.googletagmanager.com www.google-analytics.com www.gstatic.com;`,
    },
    {
      name: `img-src`,
      content: `'self' data: *.gstatic.com *.googleapis.com www.google-analytics.com www.googletagmanager.com;`,
    },
  ];

  constructor(
    private meta: Meta,
    private titleService: Title,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private gtag: GtagService
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
          this.gtag.gtagPush({
            event: 'page_view',
            pageURL: event.url
          });
      }
  });
  }

  get allowedJasmineCsp(): string {
    return environment.isJasmineTest === true ? `'unsafe-eval'` : '';
  }

  ngOnInit(): void {
    this.addMetaTag();
    this.setPageTitle();
  }

  private addMetaTag(): void {
    const tag = this.meta.getTag('http-equiv=Content-Security-Policy');
    if (!tag && !environment.production) {
      let contentSecurityPolicy = '';
      this.cspTags.forEach((csptag) => {
        contentSecurityPolicy += `${csptag.name} ${csptag.content}`;
      });
      this.meta.addTag({
        'http-equiv': 'Content-Security-Policy',
        content: contentSecurityPolicy,
      });
    }
  }

  private setPageTitle(): void {
    const appTitle = this.titleService.getTitle();
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child?.firstChild) {
            child = child.firstChild;
          }
          if (child?.snapshot.data?.title) {
            return child.snapshot.data.title;
          }
        })
      )
      .subscribe((title: string) => {
        const pageTitle = title ? `${appTitle} : ${title}` : appTitle;
        this.titleService.setTitle(pageTitle);
      });
  }
}
