// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IEnvironment } from './environment.interface';
import * as Secrets from './environment.secret';

export const environment: IEnvironment = {
  production: false,
  googleMapsApiKey: Secrets.GOOGLE_MAPS_API_KEY as string,
  hereApiKey: Secrets.HERE_API_KEY as string,
  b2cClientId: Secrets.B2C_CLIENT_ID as string,
  b2cTenantName: Secrets.B2C_TENANT_NAME as string,
  functionAppUri: Secrets.FUNCTION_APP_URI as string,
  b2CSignIn: Secrets.B2C_SIGNIN as string,
  b2CAuthority: Secrets.B2C_AUTHORITY as string,
  b2CAuthorityDomain: Secrets.B2C_KNOWN_AUTHORITIES as string,
  b2CScopes: Secrets.B2C_SCOPES as string[],
  appInsightsConfig: {
    connectionString: Secrets.APPINSIGHTS_CONNECTION_STRING as string,
  },
  gtagTrackingId: Secrets.GTAG_TRACKING_ID as string,
  isJasmineTest: true,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
