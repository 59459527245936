import { createAction, props } from '@ngrx/store';
import { Update } from '@ngrx/entity';

import { UserPolygon } from './user-polygon.model';

export const loadUserPolygons = createAction(
  '[UserPolygon] Load UserPolygons',
  props<{ userPolygons: UserPolygon[] }>()
);

export const addUserPolygon = createAction(
  '[UserPolygon] Add UserPolygon',
  props<{ userPolygon: UserPolygon }>()
);

export const upsertUserPolygon = createAction(
  '[UserPolygon] Upsert UserPolygon',
  props<{ userPolygon: UserPolygon }>()
);

export const addUserPolygons = createAction(
  '[UserPolygon] Add UserPolygons',
  props<{ userPolygons: UserPolygon[] }>()
);

export const upsertUserPolygons = createAction(
  '[UserPolygon] Upsert UserPolygons',
  props<{ userPolygons: UserPolygon[] }>()
);

export const updateUserPolygon = createAction(
  '[UserPolygon] Update UserPolygon',
  props<{ userPolygon: Update<UserPolygon> }>()
);

export const updateUserPolygons = createAction(
  '[UserPolygon] Update UserPolygons',
  props<{ userPolygons: Update<UserPolygon>[] }>()
);

export const deleteUserPolygon = createAction(
  '[UserPolygon] Delete UserPolygon',
  props<{ id: string }>()
);

export const deleteUserPolygons = createAction(
  '[UserPolygon] Delete UserPolygons',
  props<{ ids: string[] }>()
);

export const clearUserPolygons = createAction(
  '[UserPolygon] Clear UserPolygons'
);

export const drawPolygonClick = createAction(
  '[UserPolygon] Draw polygon button click'
);
