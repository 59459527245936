import { createAction, props } from '@ngrx/store';

export const isAuthenticatedAction = createAction(
  '[Auth] Is Authenticated',
  props<{ isAuthenticated: boolean }>()
);




