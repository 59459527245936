import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class NoAuthInterceptor implements HttpInterceptor {

  private unprotectedResources = ['maps.googleapis.com'];
  private isUnprotected = (resources: string[], url: string) => resources.some((resource: string) => url.indexOf(resource) !== -1);

  constructor() {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isUnprotected(this.unprotectedResources, req.url)) {
      const headers = req.headers.delete('authorization');

      const request = req.clone({
        headers
      });
      return next.handle(request);
    }
    return next.handle(req);
  }
}


