import { createReducer, on } from '@ngrx/store';
import * as AuthActions from './actions/auth.actions';

export const authFeatureKey = 'auth';

export interface State {
  isAuthenticated: boolean;
}

export const initialState: State = {
  isAuthenticated: false,
};

export const reducer = createReducer(
  initialState,

  on(AuthActions.isAuthenticatedAction, (state, { isAuthenticated }) => ({
    ...state,
    isAuthenticated,
  }))
);
