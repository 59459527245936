import { createAction, props } from '@ngrx/store';
import {Component} from '@angular/core';
import {ComponentType} from '@angular/cdk/portal';
import { TableFilters } from '../ui.reducer';

export const loadUIs = createAction(
  '[UI] Load UIs'
);

export const toggleSideBarOpen = createAction(
  '[UI] Toggle sidebar open',
);

export const openSideBar = createAction(
  '[UI] Open sidebar',
);

export const loadUIsSuccess = createAction(
  '[UI] Load UIs Success',
  props<{ data: any }>()
);

export const loadUIsFailure = createAction(
  '[UI] Load UIs Failure',
  props<{ error: any }>()
);

export const setMapView = createAction(
  '[UI] Set map view',
);

export const setTableView = createAction(
  '[UI] Set table view',
);

export const setOverlayOpen = createAction(
  '[UI] Set overLay open to true'
);

export const setOverlayClosed = createAction(
  '[UI] Set overLay open to FALSE'
);

export const setTooltipOpen = createAction(
  '[UI] Set tooltip to open',
  props<{tooltipComponent: string, tooltipData: any}>()
);

export const setTooltipClosed = createAction(
  '[UI] Set tooltip to closed',
);

export const setTableFilterOpen = createAction(
  '[UI] Set a filter popup to open',
  props<{filterName: TableFilters}>()
);

export const setTableFiltersClosed = createAction(
  '[UI] Close table filters',
);
